import { useQuery } from '@tanstack/react-query'
import { useAppContext } from 'lib/contexts/app-context'
import { httpGET } from 'lib/http'

export interface IGetCartItemsResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: IGetCartDetails
}

export interface IGetCartDetails {
  cartItems?: ICartItem[]
  shippingCharge?: number
  shippingChargeWithGst?: number
  grossAmount?: number
  gstAmount?: number
  totalAmount?: number
}

export interface ICartItem {
  id: string
  webId: number
  name: string
  sku: string
  isRestricted: boolean
  isClearance: boolean
  isTemporaryUnavailable: boolean
  isCatalogue: boolean
  isDeal: boolean
  isNewlyAdded: boolean
  productFlag: 0 | 1 | 2 | 3 | 4 | 5 | undefined
  quantity: number
  price: number
  priceWithGst: number
  productId: number
  variantId?: number
  totalPrice: number
  totalPriceWithGST: number
  isExpired: boolean
  stock: number
  uom: string
  image: {
    id: number
    url: string
    alt: string
    title: string
  }
  urlSlug?: string
}

export const getCartItems = async (cartId?: any, context?: any) => {
  if (cartId) {
    const data = await httpGET({
      url: `cart/getcartitems/${cartId}`,
      context
    })
    return data?.data as IGetCartItemsResponse
  } else {
    return null
  }
}

export const useGetCartItems = (
  isOpenQuickCartSideBar?: boolean,
  initialData?: IGetCartDetails
) => {
  const { cart } = useAppContext()

  return useQuery({
    queryKey: [
      'GET_CART_ITEMS',
      isOpenQuickCartSideBar,
      cart.isLoadingGetCartQuickInfo,
      cart.cartId
    ],
    queryFn: () => getCartItems(cart.cartId),
    enabled: !!cart.cartId && !cart.isLoadingGetCartQuickInfo,
    placeholderData: previousData =>
      previousData ?? {
        messageTitle: '',
        messageDescription: '',
        messageType: '',
        data: initialData || {}
      }
  })
}
